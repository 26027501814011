import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import store from './redux/stores/store'
import { Provider } from 'react-redux'
import { EmitProvider } from "react-emit"
import './app.styles.scss'
import DashboardPage from './pages/dashboard/dashboard.page'
import RequireLogin from './components/require-login/require-login.component'
import DefaultTemplate from './templates/default/default.template'
import AuthTemplate from './templates/auth/auth.template'
import AuthController from './pages/auth/auth.controller'
import WalletController from './pages/wallet/wallet.controller'
import ToolsController from './pages/tools/tools.controller'
import DeliveryController from './pages/delivery/delivery.controller'
import VendorController from './pages/vendor/vendor.controller'
import MarketplaceController from './pages/marketplace/marketplace.controller'
import OrderController from './pages/order/order.controller'

const AppController = () => {
  return (
    <Provider store={store}>
      <EmitProvider>
        <Router>
          <Switch>
            <Route path="/auth" component={() => <AuthTemplate><AuthController /></AuthTemplate>} />
            <Route path="/order" component={() => <RequireLogin><DefaultTemplate><OrderController /></DefaultTemplate></RequireLogin>} />
            <Route path="/wallet" component={() => <RequireLogin><DefaultTemplate><WalletController /></DefaultTemplate></RequireLogin>} />
            <Route path="/tools" component={() => <RequireLogin><DefaultTemplate><ToolsController /></DefaultTemplate></RequireLogin>} />
            <Route path="/delivery" component={() => <RequireLogin><DefaultTemplate><DeliveryController /></DefaultTemplate></RequireLogin>} />
            <Route path="/vendor" component={() => <RequireLogin><DefaultTemplate><VendorController /></DefaultTemplate></RequireLogin>} />
            <Route path="/marketplace" component={() => <RequireLogin><DefaultTemplate><MarketplaceController /></DefaultTemplate></RequireLogin>} />
            <Route path="/" component={() => <RequireLogin><DefaultTemplate><DashboardPage /></DefaultTemplate></RequireLogin>} />
          </Switch>
        </Router>
      </EmitProvider>
    </Provider>
  )
}

export default AppController
