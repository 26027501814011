import React from 'react'
import { withRouter } from 'react-router-dom'
import Dialog from '../../components/dialog/dialog.component'
import AlertDialog from '../../components/dialog/alert/alert.component'
import Loading from '../../components/loading/loading.component'
import { connect } from 'react-redux'
import Pagination from '../../components/pagination/pagination.component'
import './orders.styles.scss'
import Service from './orders.service'
import NumberFormat from 'react-number-format'
import { withEmit } from 'react-emit'
import { SEARCH_REQUESTED } from '../../templates/default/header/search/search'
import StatusLabels from './data/status-label'
import TypeLabels from './data/type-label'
import { SET_COLUMNS } from '../../redux/reducers/search/action-types'
import columns from './data/filters'

class Orders extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadOrders()
        this.props.dispatch({
            type: SET_COLUMNS,
            payload: columns
        })
        this.props.on(SEARCH_REQUESTED, this.onFilterChange);
    }

    onFilterChange = filter => {
        this.setState({
            ...this.state,
            selectedIndex: -1,
            currentPage: 1,
            filter: filter,
            loadingOrders: true
        }, () => this.service.loadOrders())
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page,
            loadingOrders: true
        }, () => this.service.loadOrders())
    }

    render() {
        return (
            <>
                {this.state.loadingOrders && <Dialog><Loading /></Dialog>}
                {this.state.showError && <AlertDialog {...this.state.alertError} />}
                <div className="orders">
                    <div className="header">
                        <h4>Orders</h4>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Trans. ID</th>
                                <th>Type</th>
                                <th>Vendor</th>
                                <th>Customer</th>
                                <th>Amount</th>
                                <th>Charges</th>
                                <th>Delivery</th>
                                <th>Status</th>
                                <th>Created</th>
                                <th>Updated</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.orders.map((order, key) => <tr key={key} onClick={() => this.props.history.push('/order/' + order.number)}>
                                    <td>{order.number}</td>
                                    <td>{TypeLabels[order.type]}</td>
                                    <td>{order.vendor.name}</td>
                                    <td>{order.customer.firstname}  {order.customer.lastname} <br/> {order.customer.email}</td>
                                    <td>{<NumberFormat value={(order.totalAmount) / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} />}</td>
                                    <td>{<NumberFormat value={order.charges / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} />}</td>
                                    <td>{order.delivery && <NumberFormat value={order.delivery.amount / 100} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} />}</td>
                                    <td>{StatusLabels[order.status]}</td>
                                    <td>{new Date(order.created).toString("dd MMM yyyy HH:mm:ss")}</td>
                                    <td>{new Date(order.updated).toString("dd MMM yyyy HH:mm:ss")}</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(withEmit(Orders)))